@import 'global/const.scss';
@import 'main/path.scss';

.header {

    display: flex;
    min-height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: $font-b;
    color: $header-text;
    font-size: 1.875em;
    text-align: center;
    text-transform: uppercase;
    background: url(/images/background/fon-personal.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 35px 20px;
    margin: 10px 0;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 10px;
        font-size: 24px;

    }

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        padding: 20px;

    }

    &:before {

        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(51, 51, 51, 0.7);
        z-index: 0;

    }

    .header-title {

        position: relative;
        z-index: 1;
        padding-top: 10px;
        margin: 0;

    }

}

.text-title {
 
    font-family: $font-b;
    color: $global-text;
    font-size: 1.875em;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 60px;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 10px;
        font-size: 24px;
        text-align: center;

    }

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        padding: 20px;

    }

}

.content-page {

    user-select: text;
    font-family: $font-a;
    color: $global-text;
    font-size: 1em;
    font-weight: 400;
    padding: 20px 60px;
    text-align: justify;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 10px;

    }

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        padding: 20px;

    }

    * { user-select: text; }

}
