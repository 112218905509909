.path {

    width: 100%;
    font-size: 14px;
    padding: 30px 60px; 
    box-sizing: border-box;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 10px; 

    }

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        padding: 20px;

    }

    ul {

        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

    }

    li {

        display: flex;
        align-items: center;
        list-style: none;

        &:first-of-type::before {

            content: '';
            padding-right: 0;

        }

        &::before {

            content: '-';
            color: $path;

        }

        &:last-of-type a { color: $path-last; }

    }

    
    a {

        margin: .25em;
        font-size: 14px;
        text-decoration: none;
        color: $path;

        span { cursor: pointer;  }

    }

}
